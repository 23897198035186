.app_main {
    content: "";
    display: table;
    clear: both;
}

.current {
    float: left;
    width: 50%;
}

h2 {
    text-align: center;
}